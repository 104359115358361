import React from 'react'
import './Slider.css'
import reloj from '../images/relojeria.svg'
import anillo from '../images/anillo.svg'
import mascarilla from '../images/mascarilla.svg'
import medidas from '../images/desinfeccion.svg'

export const Slider = () => {
    return (
        <div className="slider">
            <div className="slider__container" >
                <div className="slider__box" >
                    <img src={reloj} alt="icono slider" />
                    <h3>Las mejores marcas</h3>
                </div>
                <div className="slider__box" >
                    <img src={anillo} alt="icono slider" />
                    <h3>Joyería para cada ocasión</h3>
                </div>
                <div className="slider__box" >
                    <img src={mascarilla} alt="icono slider" />
                    <h3>Te atenderemos con una sonrisa</h3>
                </div>
                <div className="slider__box" >
                    <img src={medidas} alt="icono slider" />
                    <h3>Con todas las medidas anticovid </h3>
                </div>
            </div>
        </div>
    )
}
