import React from 'react'
import './Home.css'

import campaña from '../images/campaña.png'

export const Home = () => {
    return (
        <div className="home">
            <div className="home__landing" >
                <img src={campaña} alt="campaña" />
            </div>

        </div >
    )
}