import React from "react"
import { Home } from "../components/Home"
import { Slider } from '../components/Slider'
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Relojería Joyería Gómez" />
    <Home />
    <Slider />
  </Layout>
)

export default IndexPage
